<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <span class="brand-logo navbar-header-l d-xl-block d-none">
      <img
          :src="require(isDark ? '@/assets/images/logo/promostore-white.svg' : '@/assets/images/logo/promostore.svg') "
          alt="PromoStore"
      >
    </span>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <cart-dropdown />
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              Mi cuenta
            </p>

          </div>

          <b-avatar
            size="40"
            variant="primary"
            :text="getUserName"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'orders-list' }"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Mis pedidos</span>

        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'checkout' }"
        >
          <feather-icon
            size="16"
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>Carrito</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    CartDropdown,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    getUserName() {
      console.log(this.$store.state.account)
      if (!this.$store.state.account.user.name) {
        return ''
      }
      return this.$store.state.account.user.name.split(' ').slice(0, 2).join(' ').trim()
        .match(/\b\w/g)
        .join('')
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('account/logout')
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>

<style lang="scss" scoped>
.brand-logo{
  width: 140px!important;
  img{
    max-width: 200px!important;
  }
}
.navbar-header-l {
  left: calc(50% - 56px);
  position: relative;
}
</style>
